'use client';

import type { ReactNode } from 'react';
import React from 'react';
import { notFound } from 'next/navigation';

import { useCommunity } from '@zealy/queries';

import { FeatureFlagBanner } from '#/components/FeatureFlagBanner';
import { AppBar } from '#components/AppBar';
import { CommunityBar } from '#components/CommunityBar/CommunityBar';
import { CommunityInfoOverlay } from '#components/CommunityInfoOverlay';
import { ConnectEmail } from '#components/ConnectEmail';
import { CountdownBar } from '#components/CountdownBar/CountdownBar';
import { JoinCommunityBar } from '#components/JoinCommunityBar/JoinCommunityBar';
import { Sidebar } from '#components/Sidebar';
import { DevToolbar } from '#features/DevToolbar/DevToolbar';
import { BookCall } from '#features/Popups/BookCall';
import { FeatureFlagFeedback } from '#features/Popups/FeatureFlagFeedback';
import { FeatureFlagPopup } from '#features/Popups/FeatureFlagPopup';
import { FlaggedCommunityAlertDialog } from '#features/Popups/FlaggedCommunityAlertDialog';
import { NPSPopup } from '#features/Popups/NPS';
import { useUpdateUserCountry } from '#hooks/useUpdateUserCountry';

import { ChangelogBanner } from './ChangelogBanner/ChangelogBanner';

export interface ClientCommunityLayoutProps {
  children: ReactNode;
  subdomain: string;
}

export const ClientCommunityLayout = ({ children, subdomain }: ClientCommunityLayoutProps) => {
  const { data: community, isLoading } = useCommunity(subdomain);
  useUpdateUserCountry();

  if (isLoading) return null;

  if (!community) notFound();

  const isNotLaunched = !(community?.launchDate && new Date(community?.launchDate) <= new Date());

  return (
    <div className="flex flex-col md:flex-row min-w-0 w-full h-fill max-h-[100dvh] min-h-0 overflow-hidden">
      <AppBar community={community}>
        <CommunityBar />
        <Sidebar community={community} />
      </AppBar>

      <div className="flex flex-1 w-full min-w-0 flex-col gap-100 p-100 h-full min-h-0 ">
        <FeatureFlagBanner />
        {isNotLaunched ? (
          <CountdownBar launchDate={community?.launchDate} />
        ) : (
          <JoinCommunityBar community={community} />
        )}

        <div className="flex flex-col h-full flex-1 relative w-full min-w-0 min-h-0">
          <CommunityInfoOverlay community={community} />

          {children}
        </div>
        <DevToolbar />
      </div>

      <ChangelogBanner community={community} />
      <FeatureFlagFeedback feature="questboard" />
      <FlaggedCommunityAlertDialog />
      <ConnectEmail />
      <BookCall />
      <FeatureFlagPopup />
      <NPSPopup />
    </div>
  );
};
