import {
  Button,
  Calendar,
  EmbedURL,
  Link,
  LinkCopyGroup,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TimePicker,
} from '@zealy/design-system';
import { useCommunity, useReferralLink, useUpdateCommunity } from '@zealy/queries';

import type { Community } from '@zealy/utils';
import { Countdown } from '#components/Countdown';
import Image from 'next/image';
import { MegaphoneFilled } from '@zealy/icons';
import React from 'react';
import { UpgradeModal } from '#components/UpgradeModal';
import dayjs from 'dayjs';
import { envConfig } from '#app/config';
import { getCommunityPath } from '@zealy/utils';
import { toast } from '#components/Toaster';
import { useDidMount } from '#hooks/useDidMount';
import { useTranslations } from 'next-intl';

const ScheduleLaunch = ({
  handleLaunch,
  initialValue,
}: {
  handleLaunch: (launchDate?: Date) => void;
  initialValue: Date | undefined;
}) => {
  const t = useTranslations('onboarding.steps.launch');
  const [selectedDay, setSelectedDay] = React.useState<Date | undefined>(initialValue);

  return (
    <PopoverContent variant="secondary">
      <Calendar
        mode="single"
        selected={selectedDay}
        disabled={[{ before: new Date() }]}
        onSelect={date =>
          setSelectedDay(
            dayjs(date)
              .set('hour', selectedDay?.getHours() ?? 12)
              .set('minutes', selectedDay?.getMinutes() ?? 0)
              .startOf('hour')
              .utc()
              .toDate(),
          )
        }
      />

      <TimePicker
        className="z-50"
        name="time"
        value={{ hours: selectedDay?.getHours() ?? 12, minutes: selectedDay?.getMinutes() ?? 0 }}
        disabled={!selectedDay}
        onValueChange={(hours, minutes) => {
          setSelectedDay(
            dayjs(selectedDay).set('hour', hours).set('minutes', minutes).utc().toDate(),
          );
        }}
        size="sm"
      />

      <div className="flex justify-end gap-100 w-full mt-200">
        <Button size="sm" variant="muted" onClick={() => handleLaunch()}>
          {t('now')}
        </Button>
        <Button
          size="sm"
          color="cta"
          onClick={() => handleLaunch(selectedDay)}
          isDisabled={!selectedDay}
        >
          {t('cta')}
        </Button>
      </div>
    </PopoverContent>
  );
};

export const LaunchButton = () => {
  const t = useTranslations('onboarding.steps.launch');

  return (
    <PopoverTrigger
      className="bg-brand-secondary hover:bg-brand-secondary-hover active:bg-brand-secondary-press transition-colors text-start rounded-component-sm flex gap-component-sm-x p-component-sm"
      type="button"
    >
      <Image
        src="/nstatic/careers/grow.webp"
        width={54}
        height={40}
        alt=""
        className="flex-1 h-auto object-contain"
      />
      <div>
        <p className="body-component-sm-bold text-primary mb-50">{t('description')}</p>
        <p className="body-component-sm text-onbrand-secondary">{t('label')}</p>
      </div>
    </PopoverTrigger>
  );
};

const LaunchCountdown = ({ launchDate }: { launchDate: string }) => {
  const t = useTranslations();

  return (
    <div className="bg-component-tertiary rounded-component-sm flex flex-col gap-component-lg-y p-component-sm">
      <div className="flex justify-between">
        <p className="body-component-sm-bold text-primary">
          {t('onboarding.steps.launch.date.label')}
        </p>
        <PopoverTrigger>
          <Link size="sm">
            {t('common.edit', {
              entity: '',
            })}
          </Link>
        </PopoverTrigger>
      </div>
      <p className="body-component-sm text-secondary">
        {t('onboarding.steps.launch.date.formatted', {
          date: new Date(launchDate),
        })}
      </p>
      <Countdown date={launchDate} />
    </div>
  );
};

const ShareLaunch = ({
  community,
  undoLaunch,
}: {
  community?: Community;
  undoLaunch: () => void;
}) => {
  const t = useTranslations();
  const { data: invite } = useReferralLink();

  if (!community || !invite?.id) return null;

  const communityPath = getCommunityPath(community);

  const inviteLink = `${envConfig.appUrl}${communityPath}/invite/${invite?.id}`;

  return (
    <PopoverContent variant="secondary" className="flex-col items-stretch">
      {community.image && (
        <EmbedURL
          url={inviteLink}
          favicon="/nstatic/favicon.ico"
          image={community?.image ?? undefined}
          description={community?.description ?? ''}
          title={t('common.join-community', {
            communityName: community.name,
          })}
          className="w-full"
        />
      )}
      <LinkCopyGroup link={inviteLink} size="sm" />
      <Button size="sm" variant="muted" className="w-full" onClick={undoLaunch}>
        {t('onboarding.steps.launch.undo')}
      </Button>
    </PopoverContent>
  );
};

export const LaunchCommunity = ({
  children,
  launchDate,
}: {
  children?: React.ReactNode;
  launchDate?: string | null;
}) => {
  const didMount = useDidMount();
  const [open, setOpen] = React.useState(false);
  const [isUpgradeModalOpen, setOpenUpgradeModal] = React.useState(false);
  const t = useTranslations('onboarding.promote-launch');
  const tCommon = useTranslations('common');

  const closeUpgradeModal = () => setOpenUpgradeModal(false);
  const openUpgradeModal = () => setOpenUpgradeModal(true);

  const community = useCommunity();

  const updateCommunity = useUpdateCommunity();

  const hasNotScheduledLaunch = community.data && !community.data.launchDate;
  const hasScheduledLaunch = community.data && !!community.data.launchDate;
  const isPayingCommunity = !!community.data?.planId;

  const handleLaunch = (selectedDay?: Date) => {
    updateCommunity.mutate(
      {
        launchDate: selectedDay
          ? selectedDay.toISOString()
          : dayjs().add(3, 'minutes').toISOString(),
        requiredFields: {
          fillEmail: true,
          fillWallet: false,
          linkWallet: false,
          ...(community.data?.requiredFields ?? {}),
          linkTwitter: true,
          linkDiscord: true,
        },
      },
      {
        onError: () => toast.error(tCommon('toast-error')),
      },
    );
  };

  const handleUndoLaunch = () => {
    updateCommunity.mutate(
      {
        launchDate: null,
      },
      {
        onSuccess: () => setOpen(false),
        onError: () => toast.error(tCommon('toast-error')),
      },
    );
  };

  if (!community.data || !didMount) return null;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {community.data?.launchDate ? (
        <LaunchCountdown launchDate={community.data?.launchDate} />
      ) : (
        children
      )}
      {hasScheduledLaunch && !isPayingCommunity && (
        <>
          <div className=" pt-100">
            <Button
              size="sm"
              color={'cta'}
              className="w-full"
              variant={'muted'}
              onClick={openUpgradeModal}
              leftIcon={<MegaphoneFilled size={16} />}
            >
              {t('cta')}
            </Button>
          </div>
          <div className="pt-100 mb-100">
            <p className="text-secondary body-paragraph-sm mr-75 ml-75">{t('description')}</p>
          </div>
          <UpgradeModal open={isUpgradeModalOpen} onOpenChange={closeUpgradeModal} />
        </>
      )}

      {hasNotScheduledLaunch ? (
        <ScheduleLaunch
          handleLaunch={handleLaunch}
          initialValue={launchDate ? new Date(launchDate) : undefined}
        />
      ) : (
        <ShareLaunch undoLaunch={handleUndoLaunch} community={community.data} />
      )}
    </Popover>
  );
};
