import { useEffect, useState } from 'react';
import axios from 'axios';

import type { User } from '@zealy/queries';
import { useUpdateAuthenticatedUser, useUser } from '@zealy/queries';

/**
 * Custom hook to update the authenticated user's country and city based on their current location.
 */
export const useUpdateUserCountry = () => {
  const { data: user } = useUser<User>('me', 'root');
  const updateUser = useUpdateAuthenticatedUser();
  const [alreadySet, setAlreadySet] = useState(0);

  useEffect(() => {
    const updateUserLocation = async () => {
      if (!user || !user?.isMe || alreadySet) return;

      try {
        const response = await axios.get('https://geolocation-db.com/json/');
        const { country_name: country, city } = response.data;

        const hasCountryChanged = country && country !== user.country;
        const hasCityChanged = city && city !== user.city;

        setAlreadySet(1);
        if (hasCountryChanged || hasCityChanged) {
          await updateUser.mutate({ country, city });
        }
      } catch (error) {
        console.error(error);
      }
    };

    updateUserLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
};
